import { LocaleContext } from '@/contexts/LocaleContext';
import { DraftSystemValues, ImageDataValue } from '@/types';
import { graphql, useStaticQuery } from 'gatsby';
import { useContext } from 'react';

interface MobileChargeSolutionsData {
  id: string | number;
  locale: string;
  isPublish: 'draft' | 'publish';
  product: {
    id: number;
    title: string;
    evSolutionPageImage: ImageDataValue;
  };
}

export function useMobileChargeSolutions(): MobileChargeSolutionsData[] {
  const locale = useContext(LocaleContext);
  const { allStrapiMobileChargeSolutions } = useStaticQuery(graphql`
    query GET_MOBILE_CHARGE_SOLUTIONS {
      allStrapiMobileChargeSolutions {
        nodes {
          id
          locale
          isPublish
          product {
            id
            title
            evSolutionPageImage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                extension
                publicURL
              }
            }
          }
        }
      }
    }
  `);
  const publishElems = allStrapiMobileChargeSolutions.nodes.filter(
    (item) => item.isPublish === DraftSystemValues.publish,
  );

  return publishElems.filter((item) => item.locale === locale);
}
