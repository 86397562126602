import React from 'react';
import MainLayout from '@/layouts/MainLayout';
import { Seo } from '@/components/Seo';
import { ContactUs } from '@/components/ContactUs';
import { Hero } from '@/pageComponents/EvSolutionPage/Hero';
import { MainHeading } from '@/components/MainHeading';
import { InstallationSteps } from '@/pageComponents/EvSolutionPage/InstallationSteps';
import { ExistingChargerModes } from '@/pageComponents/EvSolutionPage/ExistingChargerModes';
import { FixedSolutions } from '@/pageComponents/EvSolutionPage/FixedSolutions';
import { TYPOGRAPHY } from '@/constants';
import { MobileSolutions } from '@/pageComponents/EvSolutionPage/MobileSolutions';
import { WebApp } from '@/pageComponents/EvSolutionPage/WebApp';

const UserSolution = ({ pageContext }) => {
  const {
    locale,
    seo,
    heroSection,
    chargerInstallationSteps,
    existingChargerModes,
    fixedChargingSolutions,
    mobileChargingSolutions,
    mobileChargingProduct,
    ebeeWebApp,
    contactUs,
  } = pageContext.data;

  return (
    <MainLayout locale={locale}>
      <Seo data={seo} />
      <MainHeading text={seo.mainHeading} />

      <Hero data={heroSection} />
      <InstallationSteps data={chargerInstallationSteps} />
      <ExistingChargerModes data={existingChargerModes} />
      <FixedSolutions data={fixedChargingSolutions} />
      <MobileSolutions
        data={mobileChargingSolutions}
        products={mobileChargingProduct}
      />
      <WebApp data={ebeeWebApp} />
      <ContactUs
        sectionData={contactUs}
        titleFont={TYPOGRAPHY.title3SemiBold32}
      />
    </MainLayout>
  );
};

export default UserSolution;
