import { Container } from '@/components/Container';
import { COLORS, TYPOGRAPHY } from '@/constants';
import { ImageDataValue } from '@/types';
import React, { FC } from 'react';
import Lottie from 'react-lottie';
import styled from 'styled-components';

interface HeroProps {
  data: {
    title: string;
    description: string;
    image: ImageDataValue;
  };
}

const Hero: FC<HeroProps> = ({ data }) => {
  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: undefined,
    path: data.image?.localFile.publicURL,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Root>
      <StyledContainer>
        <AnimationWrap>
          <Lottie options={animationOptions} height={670} width={670} />
        </AnimationWrap>
        <ContentWrap>
          <Title>{data.title}</Title>
          <Description>{data.description}</Description>
        </ContentWrap>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  background-color: ${COLORS.lightGray2};
  margin-top: -86px;
  overflow: hidden;
  height: 100vh;
  @media (max-width: 1024px) {
    height: auto;
  }
  @media (max-width: 500px) {
    margin-top: -117px;
  }
`;

const StyledContainer = styled(Container)`
  padding-top: 225px;
  padding-bottom: 230px;
  position: relative;
  height: 100%;
  max-width: none;
  padding-left: 7.5vw;
  padding-right: 7.5vw;
  display: flex;
  align-items: center;
  @media (max-width: 1300px) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media (max-width: 1024px) {
    padding-top: 150px;
    padding-bottom: 500px;
    padding-left: 32px;
    padding-right: 32px;
  }
  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
  }
  @media (max-width: 550px) {
    padding-bottom: 300px;
  }
`;

const AnimationWrap = styled.div`
  position: absolute;
  right: -6vw;
  top: 50%;
  transform: translateY(-50%);
  div[aria-label='animation'] {
    width: 46.52vw !important;
    height: 46.52vw !important;
  }
  @media (max-width: 1400px) {
    right: -10%;
  }

  @media (max-width: 1300px) {
    top: 18%;
    transform: none;
    div[aria-label='animation'] {
      width: 500px !important;
      height: 500px !important;
    }
  }
  @media (max-width: 1024px) {
    right: auto;
    top: auto;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }
  @media (max-width: 550px) {
    div[aria-label='animation'] {
      width: 300px !important;
      height: 300px !important;
    }
  }
`;

const ContentWrap = styled.div`
  /* max-width: 750px; */
  max-width: 52.08vw;
  @media (max-width: 1300px) {
    max-width: 600px;
  }
  @media (max-width: 1024px) {
    max-width: none;
  }
`;

const Title = styled.h2`
  font-size: 4.44vw;
  line-height: 5.2vw;
  color: ${COLORS.black1};
  margin-bottom: 2.77vw;
  @media (max-width: 1300px) {
    ${TYPOGRAPHY.title1SemiBold64};
    margin-bottom: 40px;
  }
  @media (max-width: 768px) {
    ${TYPOGRAPHY.title3SemiBold32};
    margin-bottom: 16px;
  }
`;

const Description = styled.p`
  font-size: 1.25vw;
  line-height: 2.22vw;
  color: ${COLORS.black1};
  @media (max-width: 1300px) {
    ${TYPOGRAPHY.body1Regular18};
  }
`;

export default Hero;
