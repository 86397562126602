import React, { FC } from 'react';
import { Container } from '@/components/Container';
import styled from 'styled-components';
import { COLORS, TYPOGRAPHY } from '@/constants';

interface ExistingChargerModesProps {
  data: {
    title: string;
    subtitle: string;
    firstAnswer: string;
    secondAnswer: string;
    thirdAnswer: string;
    description: string;
  };
}

const ExistingChargerModes: FC<ExistingChargerModesProps> = ({ data }) => {
  return (
    <section>
      <StyledContainer>
        <Title>{data.title}</Title>
        <Subtitle>{data.subtitle}</Subtitle>
        <AnswerList>
          <AnswerItem>
            <AnswerText>{data.firstAnswer}</AnswerText>
          </AnswerItem>
          <AnswerItem>
            <AnswerText>{data.secondAnswer}</AnswerText>
          </AnswerItem>
          <AnswerItem>
            <AnswerText>{data.thirdAnswer}</AnswerText>
          </AnswerItem>
        </AnswerList>
        <Description>{data.description}</Description>
      </StyledContainer>
    </section>
  );
};

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 90px;
  padding-bottom: 90px;
  padding: 90px 14.72%;
  @media (min-width: 1440px) {
    padding-left: 212px;
    padding-right: 212px;
  }
  @media (max-width: 1300px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media (max-width: 1024px) {
    padding-top: 50px;
    padding-bottom: 50px;
    padding: 50px 32px;
  }
  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
    align-items: flex-start;
  }
`;

const Title = styled.h2`
  ${TYPOGRAPHY.title2SemiBold48};
  color: ${COLORS.black1};
  text-align: center;
  margin-bottom: 32px;
  @media (max-width: 768px) {
    text-align: left;
    ${TYPOGRAPHY.headlineMedium22};
  }
`;

const Subtitle = styled.p`
  ${TYPOGRAPHY.body1Regular18};
  color: ${COLORS.black1};
  text-align: center;
  max-width: 820px;
  margin-bottom: 60px;
  @media (max-width: 768px) {
    text-align: left;
  }
`;

const AnswerList = styled.ul`
  margin-bottom: 60px;
  counter-reset: answer-counter;
`;

const AnswerItem = styled.li`
  display: flex;
  align-items: center;
  position: relative;
  padding: 40px 77px 40px 210px;
  border: 2px solid ${COLORS.purple};
  border-radius: 20px;
  margin-bottom: 30px;

  min-height: 180px;
  :last-child {
    margin-bottom: 0;
  }
  ::before {
    counter-increment: answer-counter;
    content: counter(answer-counter);
    position: absolute;
    left: 60px;
    top: 50%;
    opacity: 0.2;
    font-size: 144px;
    font-weight: bold;
    transform: translateY(-50%);
    color: ${COLORS.purple};
  }
  :nth-child(even) {
    border-color: ${COLORS.orange};
    ::before {
      color: ${COLORS.orange};
    }
  }

  @media (max-width: 768px) {
    padding: 113px 16px 16px 16px;
    margin-bottom: 32px;

    ::before {
      font-size: 100px;
      line-height: 85px;
      top: 16px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

const AnswerText = styled.p`
  ${TYPOGRAPHY.body1Regular18};

  color: ${COLORS.black1};
`;

const Description = styled.p`
  ${TYPOGRAPHY.body1Regular18};
  color: ${COLORS.black1};
`;

export default ExistingChargerModes;
