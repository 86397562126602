import { Container } from '@/components/Container';
import { Image } from '@/components/Image';
import { COLORS, TYPOGRAPHY } from '@/constants';
import { useFixedChargeSolutions } from '@/hooks/useFixedChargeSolutions';
import { DraftSystemValues, ImageDataValue } from '@/types';
import React, { FC } from 'react';
import styled from 'styled-components';

interface ProductData {
  id: number;
  link: string | null;
  isPublish: string;
  productImage: ImageDataValue;
}

interface FixedSolutionsProps {
  data: {
    title: string;
    fixedChargingProduct: ProductData[];
  };
}

const FixedSolutions: FC<FixedSolutionsProps> = ({ data }) => {
  const cmsProducts = useFixedChargeSolutions();
  const externalProducts = data.fixedChargingProduct.filter(
    (item) => item.isPublish === DraftSystemValues.publish,
  );

  return (
    <section>
      <StyledContainer>
        {data.title && <Title>{data.title}</Title>}
        <ProductList>
          {externalProducts.map((item) => {
            return (
              <ProductItem key={item.id}>
                {item.link && <ProductLink href={item.link} target="_blank" />}
                <ProductImageWrap>
                  <Image
                    localFile={item?.productImage.localFile}
                    alt="Product"
                  />
                </ProductImageWrap>
              </ProductItem>
            );
          })}
          {cmsProducts &&
            cmsProducts.map((item) => {
              return (
                <ProductItem key={item.id}>
                  <ProductLink
                    href={
                      item.locale === 'en'
                        ? `/product/${item.product.id}`
                        : `/${item.locale}/product/${item.product.id}`
                    }
                    target="_blank"
                  />
                  <ProductImageWrap>
                    <Image
                      localFile={item.product.evSolutionPageImage.localFile}
                      alt="Product"
                    />
                  </ProductImageWrap>
                </ProductItem>
              );
            })}
        </ProductList>
      </StyledContainer>
    </section>
  );
};

const StyledContainer = styled(Container)`
  padding-top: 90px;
  padding-bottom: 90px;
  @media (max-width: 1024px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const Title = styled.h2`
  ${TYPOGRAPHY.title2SemiBold48};
  color: ${COLORS.black1};
  margin-bottom: 70px;
  text-align: center;
  @media (max-width: 768px) {
    ${TYPOGRAPHY.headlineSemiBold22};
    margin-bottom: 32px;
  }
  @media (max-width: 650px) {
    text-align: left;
  }
`;

const ProductImageWrap = styled.div`
  transition: transform 0.3s ease;
  display: flex;
  @media (max-width: 600px) {
    height: 340px;
  }
`;

const ProductList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  @media (max-width: 650px) {
    display: block;
  }
`;

const ProductItem = styled.li`
  display: flex;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  z-index: 0;
  :hover {
    ${ProductImageWrap} {
      transform: scale(1.05);
    }
  }
  @media (max-width: 650px) {
    margin-bottom: 32px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

const ProductLink = styled.a`
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
`;

export default FixedSolutions;
