import { Container } from '@/components/Container';
import { COLORS, TYPOGRAPHY } from '@/constants';
import { UiLink } from '@/ui/components/UiLink';
import React, { FC } from 'react';
import styled from 'styled-components';

interface InstallationStepsProps {
  data: {
    firstStep: string;
    secondStep: string;
    thirdStep: string;
    fourthStep: string;
    fivethStep: string;
    sixthStep: string;
    buttonText: string;
  };
}

const InstallationSteps: FC<InstallationStepsProps> = ({ data }) => {
  return (
    <section>
      <StyledContainer>
        <StepList>
          <StepItem>
            <StepText>{data.firstStep}</StepText>
            <ArrowElem />
          </StepItem>
          <StepItem>
            <StepText>{data.secondStep}</StepText>
            <ArrowElem />
          </StepItem>
          <StepItem>
            <StepText>{data.thirdStep}</StepText>
            <ArrowElem />
          </StepItem>
          <StepItem>
            <StepText>{data.fourthStep}</StepText>
            <ArrowElem />
          </StepItem>
          <StepItem>
            <StepText>{data.fivethStep}</StepText>
            <ArrowElem />
          </StepItem>
          <StepItem>
            <StepText>{data.sixthStep}</StepText>
            <ArrowElem />
          </StepItem>
        </StepList>

        {/* <StyledUiLink text={data.buttonText} customTheme="primary" link="/" /> */}
      </StyledContainer>
    </section>
  );
};

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 120px;
  padding-bottom: 90px;
  @media (max-width: 1024px) {
    padding-top: 100px;
    padding-bottom: 50px;
  }
`;

const StepList = styled.ul`
  counter-reset: step-counter;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px 23px;
  margin-bottom: 60px;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 600px) {
    display: block;
  }
`;

const ArrowElem = styled.div`
  position: absolute;
  width: 38px;
  height: 104px;
  right: -38px;
  top: 50%;
  transform: translateY(-50%);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  z-index: 1;
  @media (max-width: 600px) {
    width: 104px;
    height: 41px;
    right: auto;
    top: auto;

    left: 50%;
    bottom: -41px;
    transform: translateX(-50%);
  }
`;

const StepItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 50px;
  min-height: 156px;
  border: 2px solid ${COLORS.purple};
  border-radius: 16px;
  position: relative;

  ${ArrowElem} {
    background-image: url('/images/install-purple-arrow.svg');
  }

  ::before,
  ::after {
    position: absolute;
  }
  ::before {
    content: '';
    width: 49px;
    height: 49px;
    right: -2px;
    top: -2px;
    background-color: ${COLORS.purple};
    border-bottom-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  ::after {
    counter-increment: step-counter;
    content: counter(step-counter);
    font-weight: 500;
    font-size: 31px;
    line-height: 39px;
    right: 13px;
    top: 3px;
    color: ${COLORS.white};
  }

  :nth-child(even) {
    border-color: ${COLORS.orange};
    ${ArrowElem} {
      background-image: url('/images/install-orange-arrow.svg');
    }
    ::before {
      background-color: ${COLORS.orange};
    }
  }
  :nth-child(3n) {
    ${ArrowElem} {
      background-image: none;
    }
  }

  @media (max-width: 1300px) {
    padding-left: 30px;
  }

  @media (max-width: 1024px) {
    :nth-child(n) {
      ${ArrowElem} {
        background-image: url('/images/install-purple-arrow.svg');
      }
    }
    :nth-child(even) {
      ${ArrowElem} {
        background-image: url('/images/install-orange-arrow.svg');
      }
    }
    :nth-child(2n) {
      ${ArrowElem} {
        background-image: none;
      }
    }
  }
  @media (max-width: 600px) {
    padding: 60px 30px;
    padding-right: 30px;
    margin-bottom: 34px;

    :nth-child(odd) {
      ${ArrowElem} {
        background-image: url('/images/install-purple-arrow-mobile.svg');
      }
    }
    :nth-child(even) {
      ${ArrowElem} {
        background-image: url('/images/install-orange-arrow-mobile.svg');
      }
    }
    :last-child {
      margin-bottom: 0;
      ${ArrowElem} {
        background-image: none;
      }
    }
  }
`;

const StepText = styled.p`
  ${TYPOGRAPHY.body2Light16};
  color: ${COLORS.black1};
`;

export default InstallationSteps;
