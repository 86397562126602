import { LocaleContext } from '@/contexts/LocaleContext';
import { DraftSystemValues, ImageDataValue } from '@/types';
import { graphql, useStaticQuery } from 'gatsby';
import { useContext } from 'react';

interface FixedChargeSolutionsData {
  id: string | number;
  locale: string;
  isPublish: 'draft' | 'publish';
  product: {
    id: number;
    title: string;
    evSolutionPageImage: ImageDataValue;
  };
}

export function useFixedChargeSolutions(): FixedChargeSolutionsData[] {
  const locale = useContext(LocaleContext);
  const { allStrapiFixedChargeSolutions } = useStaticQuery(graphql`
    query GET_FIXED_CHARGE_SOLUTIONS {
      allStrapiFixedChargeSolutions {
        nodes {
          id
          isPublish
          locale
          product {
            id
            title
            evSolutionPageImage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                extension
                publicURL
              }
            }
          }
        }
      }
    }
  `);
  const publishElems = allStrapiFixedChargeSolutions.nodes.filter(
    (item) => item.isPublish === DraftSystemValues.publish,
  );

  return publishElems.filter((item) => item.locale === locale);
}
