import { Container } from '@/components/Container';
import { Image } from '@/components/Image';
import { COLORS, TYPOGRAPHY } from '@/constants';
import { ImageDataValue } from '@/types';
import React, { FC } from 'react';
import styled from 'styled-components';

interface StepData {
  id: string;
  title: string;
  icon: ImageDataValue;
}

interface WebAppProps {
  data: {
    title: string;
    subtitle: string;
    ebeeWebAppStep: StepData[];
  };
}

const WebApp: FC<WebAppProps> = ({ data }) => {
  return (
    <section>
      <StyledContainer>
        <Title>{data.title}</Title>
        <Subtitle>{data.subtitle}</Subtitle>
        <StepList>
          {data.ebeeWebAppStep.map((item) => {
            return (
              <StepItem key={item.id}>
                <IconWrap>
                  <Icon localFile={item.icon?.localFile} alt={item.title} />
                </IconWrap>
                <StepItemTitle>{item.title}</StepItemTitle>
              </StepItem>
            );
          })}
        </StepList>
      </StyledContainer>
    </section>
  );
};

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 90px;
  padding-bottom: 90px;
  @media (max-width: 1024px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const Title = styled.h2`
  ${TYPOGRAPHY.title2SemiBold48};
  color: ${COLORS.black1};
  margin-bottom: 32px;
  text-align: center;
  @media (max-width: 768px) {
    ${TYPOGRAPHY.headlineSemiBold22};
    margin-bottom: 32px;
  }
  @media (max-width: 650px) {
    text-align: left;
    align-self: flex-start;
  }
`;

const Subtitle = styled.p`
  ${TYPOGRAPHY.body1Regular18};
  color: ${COLORS.black1};
  text-align: center;
  margin-bottom: 60px;
  max-width: 940px;
  @media (max-width: 768px) {
    margin-bottom: 32px;
    text-align: left;
  }
`;

const StepList = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StepItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 160px;
  ::before {
    content: '';
    position: absolute;
    width: 88%;
    height: 22%;
    background: url('/images/arrow-opacity.svg') no-repeat center;
    background-size: cover;
    left: 13vw;
    top: 30px;
  }
  :last-child {
    ::before {
      content: none;
    }
  }
  @media (min-width: 1440px) {
    ::before {
      left: 115%;
    }
  }
  @media (max-width: 1100px) {
    ::before {
      content: none;
    }
  }
  @media (max-width: 768px) {
    margin-bottom: 120px;
    ::before {
      content: '';
      width: 80px;
      height: 25px;
      transform: translateX(-50%) rotate(90deg);
      left: 50%;
      top: calc(100% + 50px);
    }
    :last-child {
      margin-bottom: 0;
    }
  }
`;

const IconWrap = styled.div`
  width: 103px;
  height: 103px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: ${COLORS.white};
  box-shadow: 0px 3px 12px rgba(87, 75, 247, 0.2);
  border-radius: 15px;
  margin-bottom: 40px;
`;

const Icon = styled(Image)`
  max-width: 60px;
`;

const StepItemTitle = styled.p`
  ${TYPOGRAPHY.headlineSemiBold22};
  color: ${COLORS.black1};
  text-align: center;
`;

export default WebApp;
